.item {
  width: 30%;
}

.item__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding: 0.1rem 2rem 1.65rem 2rem; */
  padding: 0.5rem 1.5rem;
  text-align: center;
}
.item__image {
  transition: all 300ms ease;
  width: 4rem;
}

.item__name {
  font-size: 0.9rem;
  transform: scale(0);
  opacity: 0;
  transition: all 300ms ease;
}

.item__wrapper:hover .item__name {
  opacity: 1;
  transform: scale(1);
}

.item__wrapper:hover .item__image {
  filter: brightness(80%);
  opacity: 0.86;
  transform: scale(0.9);
}

@media screen and (max-width: 1143px) {
  .item {
    width: 15%;
    padding-right: 33%;
  }

  .item__wrapper {
    margin-right: 2rem;
  }
}
@media screen and (max-width: 852px) {
}
