@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* scroll-behavior: smooth; */
}

a {
  color: rgb(226, 226, 226);
  text-decoration: none;
}

li {
  list-style: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #001f3f;
}

nav {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background-color: #001f3f;
}

.logo {
  color: rgb(226, 226, 226);
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 20px;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 40%;
}
.nav-links > li {
  cursor: pointer;
}

@-webkit-keyframes fallAnimation {
  0% {
    opacity: 0;
    top: -40px;
  }

  100% {
    top: 0px;
  }
}

@keyframes fallAnimation {
  0% {
    opacity: 0;
    top: -40px;
  }

  100% {
    top: 0px;
  }
}
@-webkit-keyframes upAnimation {
  0% {
    opacity: 0;
    bottom: -40px;
  }

  100% {
    bottom: 0px;
  }
}
@keyframes upAnimation {
  0% {
    opacity: 0;
    bottom: -40px;
  }

  100% {
    bottom: 0px;
  }
}

.nav-links__up-animation {
  -webkit-animation: upAnimation 800ms ease;
          animation: upAnimation 800ms ease;
}

.nav-links__down-animation {
  -webkit-animation: fallAnimation 800ms ease;
          animation: fallAnimation 800ms ease;
}

.nav-links a {
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
}

.nav-links__hover-effect--white::after {
  content: "";
  position: absolute;
  right: 0%;
  bottom: -3px;
  height: 3px;
  width: 0%;
  background-color: rgb(226, 226, 226);
  transition: all 350ms ease;
}

.nav-links__hover-effect--white:hover:after {
  width: 98%;
  left: 0;
}

/* Burger */
/* .burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: rgb(226, 226, 226);
  margin: 5px;
  transition: all 0.3s ease;
} */

/* Media queries */

@media screen and (max-width: 1043px) {
  .nav-links {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    width: 90%;
  }
  .header__name {
    display: none;
  }
  .nav-links {
    margin-left: 50px;
  }
  .nav-links li {
    font-size: 12px;
    padding-right: 18px;
  }

  nav {
    padding: 2rem;
    justify-content: space-between;
  }

  /* .nav-links {  sidebar menu on click
    position: absolute;
    right: 0px;
    top: 8vh;
    height: 92vh;
    background-color: #083358;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  } */

  /* .nav-links li {
    opacity: 1;
  } */
}

.showcase {
  display: flex;
  max-height: 70vh;
}

.row {
  padding: 5% 7%;
  background-color: #001f3f;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

/* Intorduction part */
.showcase__name {
  opacity: 1;
  font-size: 4rem;
  font-weight: bold;
  padding-bottom: 1%;
  color: rgb(226, 226, 226);
}

.showcase__para {
  font-size: 1.4rem;
  color: rgb(226, 226, 226);
  padding: 0 0 2% 0;
}

.showcase__btn {
  width: 164px;
  font-size: 20px;
  color: #242424;
  background-color: rgb(226, 226, 226);
  border-style: none;
  padding: 10px 24px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 350ms ease;
  position: relative;
}

.showcase__btn:hover {
  background-color: #083358;
  color: rgb(226, 226, 226);
  border: 0.2px white solid;
}

.showcase__button--popper {
  position: absolute;
  left: 480px;
}

.showcase__btn:hover .showcase__button--popper {
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
}

/* Media */

.media-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 15% 0 0 0.5%;
  background-color: #001f3f;
}
.fa-linkedin,
.fa-github,
.fa-file-pdf {
  width: 60px !important;
  color: rgb(226, 226, 226);
}

.media-container__list-item {
  font-size: 4rem;
  color: black;
  transition: all 350ms ease;
}

.media-container__list-item:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/* Media queries */

@media screen and (max-width: 1043px) {
  .showcase {
    flex-direction: column;
    align-self: center !important;
  }

  .row {
    text-align: center;
  }
  .showcase__name {
    font-size: 2.4rem !important;
  }

  .media-container {
    margin: 0 auto !important;
    order: 1;
    display: flex;
    flex-direction: column;
  }

  .media-container__list {
    display: flex;
    flex-direction: row;
  }

  .media-container__list-item {
    padding: 0 10px;
  }

  .showcase__name {
    padding-bottom: 3rem;
  }

  .showcase__para {
    display: none;
  }

  .showcase__btn {
    margin-bottom: 1rem;
  }
}

.projects {
  margin-top: 4%;
  /* padding: 5%; */
  width: 100%;
  background-color: rgb(226, 226, 226);
}

.projects_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2%;
}

.projects__heading {
  margin-bottom: 6%;
  font-size: 3rem;
}

.projects_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects__item {
  display: grid;
  box-shadow: 8px 5px 14px 2px rgba(0, 0, 0, 0.86);
  width: 69%;
  height: 550px;
  position: relative;
  overflow: hidden;
  margin: 0 auto 9% auto;
}

.projects__item__wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  /* background-color: #1c1d25; */
  transition: all 350ms ease;
}

.projects__item:hover > .projects__item__wrapper:before {
  right: 0;
  width: 140%;
  opacity: 1;
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

.projects__item_image {
  /* width: 100%; */
  /* height: 100%; */
  object-fit: contain;
  transition: all 300ms ease;
}

.projects__item:hover .projects__item_image {
  opacity: 0.7;
  -webkit-filter: blur(6px);
          filter: blur(6px);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.projects__item_info {
  position: absolute;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  top: 30%;
  margin-left: 55px;
  opacity: 0;
  transition: all 200ms ease;
}

.projects__item_info h2 {
  padding-top: 10px;
  font-weight: lighter;
}

.projects__index {
  color: black;
  transition: all 300ms ease;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  font-size: 100px;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 22px;
}

.projects__item button {
  transition: all 200ms ease;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);

  opacity: 0;
  width: 190px;
  position: absolute;
  right: 100px;
  bottom: 60px;
  background-color: #001f3f;
  color: rgb(226, 226, 226);
}

.projects__item:hover .projects__item_info {
  opacity: 1;
  z-index: 2;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
}

.projects__item:hover button {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.projects__item:hover .projects__index {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

/* media queries */

@media screen and (max-width: 1300px) {
  .projects__item {
    width: 800px !important;
  }
}

@media screen and (max-width: 990px) {
  .projects__item {
    width: 680px !important;
  }
}

@media screen and (max-width: 930px) {
  .projects__item {
    width: 580px !important;
  }

  .projects__item:hover .projects__item_info {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
}

@media screen and (max-width: 768px) {
  .projects__item {
    width: 440px !important;
  }

  .projects__item_info {
    font-size: 14px;
  }
  .projects__item:hover .projects__item_info {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }

  .projects__item button {
    right: 1.5rem;
    bottom: 1.8rem;
  }

  .projects__index {
    font-size: 70px;
  }
}

/* Majority of the styles for the projects items are in Projects.css */

.footer {
  background-color: #001f3f;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  cursor: pointer;
}

.footer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(226, 226, 226);
  position: relative;
  /* background-color: red; */
  letter-spacing: 5px;
}

.footer__arrow {
  cursor: pointer;
  opacity: 1;
  padding-left: 1.3rem;
  position: absolute;
  opacity: 0;
  transition: all 300ms ease;
}

.footer__content:hover > .footer__arrow {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  opacity: 1;
}

.footer__media {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 500px; /* background-color: black; */
  display: flex;
  /* margin-right: 10px; */
}

/* .footer__list__item {
  color: rgb(226, 226, 226);
}

.footer__list__item:hover {
  cursor: pointer;
} */

.footer__hover-effect--white::after {
  content: "";
  position: absolute;
  right: 0%;
  bottom: -3px;
  height: 3px;
  width: 0%;
  background-color: rgb(226, 226, 226);
  transition: all 350ms ease;
}

.footer__hover-effect--white:hover:after {
  width: 98%;
  left: 0;
}

.footer__item {
  letter-spacing: 3px;
  font-weight: lighter;
  font-size: 14px;
  position: relative;
  cursor: pointer;
}

.footer__copyright {
  color: rgb(226, 226, 226);
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  .footer {
  }
  .footer__media {
    width: 80%;
  }
  .form__container {
    padding: 0;
  }
}

.aboutMe {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* width: 100vw;
  height: 50vh; */
}

.aboutMe__close {
  position: relative;
  left: 92%;
  top: 38px;
  cursor: pointer;
  transition: all 300ms ease;
}

.aboutMe__close:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.aboutMe__content {
  margin: 3rem 0;
  background-color: white;
  justify-content: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  border: solid 2px black;
  border-radius: 39px;
  max-width: 1200px;
  width: 90%;
  height: 90vh;
  transition: all 300ms ease;
}

.aboutMe__form {
  border-radius: 20px;
  width: 50%;
  transition: all 300ms ease;
}

@media screen and (max-width: 852px) {
  .aboutMe__content {
    padding: 23px;
    background-color: white;
    text-align: center;
    margin: none;
    display: inline;
    width: 100vw;
    border: 0px;
    border-radius: 0;
    max-width: 1200px;
    width: 90%;
    height: 100%;
  }
  .aboutMe__form {
    width: 100%;
    /* height: 100vh; */
  }

  .aboutMe {
    margin: 0;
  }
}

.item {
  width: 30%;
}

.item__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding: 0.1rem 2rem 1.65rem 2rem; */
  padding: 0.5rem 1.5rem;
  text-align: center;
}
.item__image {
  transition: all 300ms ease;
  width: 4rem;
}

.item__name {
  font-size: 0.9rem;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  transition: all 300ms ease;
}

.item__wrapper:hover .item__name {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.item__wrapper:hover .item__image {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  opacity: 0.86;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

@media screen and (max-width: 1143px) {
  .item {
    width: 15%;
    padding-right: 33%;
  }

  .item__wrapper {
    margin-right: 2rem;
  }
}
@media screen and (max-width: 852px) {
}

.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 48%;
  padding: 120px 72px;
  /* background-color: red; */
}

.skills__info {
  /* background-color: blue; */
  width: 100%;
  line-height: 1.6;
}

.skills__info h1 {
  padding-bottom: 0.6rem;
}

.skills__info h3 {
  padding-bottom: 1rem;
}

.skills__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  /* background-color: green; */
}

/* @media screen and (max-width: 975px) {
  
}; */

@media screen and (max-width: 852px) {
  .skills {
    max-width: 100%;
    padding: 0;
  }
  .skills__info {
    /* background-color: red; */
    width: 1000px;
  }

  .skills__list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.form__input {
  outline: none;
  border: none;
  height: 1.6rem;
  border-bottom: 3px solid black;
  transition: all 300s ease;
}

/* .form__input--red {     toggle this class on click
  outline: none;
  border: none;
  height: 1.6rem;
  border-bottom: 3px solid red;
  transition: all 300s ease;
} */

.form__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.form__container {
  /* background-color: red; */
  padding: 3.5rem 2rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.form__container h3 {
  padding-top: 3.4rem;
}

.form__message {
  outline: none;
  border: none;
  height: 93px;
  border-bottom: 3px solid black;
}

.form__button {
  width: 164px;
  font-size: 20px;
  color: #242424;
  background-color: rgb(226, 226, 226);
  border-style: none;
  padding: 10px 24px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 350ms ease;
  position: relative;
}

.form__button:hover {
  color: rgb(226, 226, 226);
  background-color: #242424;
}

@media screen and (max-width: 852px) {
  .form {
    padding: 0;
  }
  .form__item label {
    padding-top: 22px;
  }
}

