.aboutMe {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* width: 100vw;
  height: 50vh; */
}

.aboutMe__close {
  position: relative;
  left: 92%;
  top: 38px;
  cursor: pointer;
  transition: all 300ms ease;
}

.aboutMe__close:hover {
  transform: scale(1.2);
}

.aboutMe__content {
  margin: 3rem 0;
  background-color: white;
  justify-content: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  border: solid 2px black;
  border-radius: 39px;
  max-width: 1200px;
  width: 90%;
  height: 90vh;
  transition: all 300ms ease;
}

.aboutMe__form {
  border-radius: 20px;
  width: 50%;
  transition: all 300ms ease;
}

@media screen and (max-width: 852px) {
  .aboutMe__content {
    padding: 23px;
    background-color: white;
    text-align: center;
    margin: none;
    display: inline;
    width: 100vw;
    border: 0px;
    border-radius: 0;
    max-width: 1200px;
    width: 90%;
    height: 100%;
  }
  .aboutMe__form {
    width: 100%;
    /* height: 100vh; */
  }

  .aboutMe {
    margin: 0;
  }
}
