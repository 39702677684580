.footer {
  background-color: #001f3f;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  cursor: pointer;
}

.footer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(226, 226, 226);
  position: relative;
  /* background-color: red; */
  letter-spacing: 5px;
}

.footer__arrow {
  cursor: pointer;
  opacity: 1;
  padding-left: 1.3rem;
  position: absolute;
  opacity: 0;
  transition: all 300ms ease;
}

.footer__content:hover > .footer__arrow {
  transform: translateX(100px);
  opacity: 1;
}

.footer__media {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 500px; /* background-color: black; */
  display: flex;
  /* margin-right: 10px; */
}

/* .footer__list__item {
  color: rgb(226, 226, 226);
}

.footer__list__item:hover {
  cursor: pointer;
} */

.footer__hover-effect--white::after {
  content: "";
  position: absolute;
  right: 0%;
  bottom: -3px;
  height: 3px;
  width: 0%;
  background-color: rgb(226, 226, 226);
  transition: all 350ms ease;
}

.footer__hover-effect--white:hover:after {
  width: 98%;
  left: 0;
}

.footer__item {
  letter-spacing: 3px;
  font-weight: lighter;
  font-size: 14px;
  position: relative;
  cursor: pointer;
}

.footer__copyright {
  color: rgb(226, 226, 226);
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  .footer {
  }
  .footer__media {
    width: 80%;
  }
  .form__container {
    padding: 0;
  }
}
