.showcase {
  display: flex;
  max-height: 70vh;
}

.row {
  padding: 5% 7%;
  background-color: #001f3f;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

/* Intorduction part */
.showcase__name {
  opacity: 1;
  font-size: 4rem;
  font-weight: bold;
  padding-bottom: 1%;
  color: rgb(226, 226, 226);
}

.showcase__para {
  font-size: 1.4rem;
  color: rgb(226, 226, 226);
  padding: 0 0 2% 0;
}

.showcase__btn {
  width: 164px;
  font-size: 20px;
  color: #242424;
  background-color: rgb(226, 226, 226);
  border-style: none;
  padding: 10px 24px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 350ms ease;
  position: relative;
}

.showcase__btn:hover {
  background-color: #083358;
  color: rgb(226, 226, 226);
  border: 0.2px white solid;
}

.showcase__button--popper {
  position: absolute;
  left: 480px;
}

.showcase__btn:hover .showcase__button--popper {
  transform: translateY(100px);
}

/* Media */

.media-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 15% 0 0 0.5%;
  background-color: #001f3f;
}
.fa-linkedin,
.fa-github,
.fa-file-pdf {
  width: 60px !important;
  color: rgb(226, 226, 226);
}

.media-container__list-item {
  font-size: 4rem;
  color: black;
  transition: all 350ms ease;
}

.media-container__list-item:hover {
  transform: scale(1.2);
}

/* Media queries */

@media screen and (max-width: 1043px) {
  .showcase {
    flex-direction: column;
    align-self: center !important;
  }

  .row {
    text-align: center;
  }
  .showcase__name {
    font-size: 2.4rem !important;
  }

  .media-container {
    margin: 0 auto !important;
    order: 1;
    display: flex;
    flex-direction: column;
  }

  .media-container__list {
    display: flex;
    flex-direction: row;
  }

  .media-container__list-item {
    padding: 0 10px;
  }

  .showcase__name {
    padding-bottom: 3rem;
  }

  .showcase__para {
    display: none;
  }

  .showcase__btn {
    margin-bottom: 1rem;
  }
}
