.projects {
  margin-top: 4%;
  /* padding: 5%; */
  width: 100%;
  background-color: rgb(226, 226, 226);
}

.projects_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2%;
}

.projects__heading {
  margin-bottom: 6%;
  font-size: 3rem;
}

.projects_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects__item {
  display: grid;
  -webkit-box-shadow: 8px 5px 14px 2px rgba(0, 0, 0, 0.86);
  box-shadow: 8px 5px 14px 2px rgba(0, 0, 0, 0.86);
  width: 69%;
  height: 550px;
  position: relative;
  overflow: hidden;
  margin: 0 auto 9% auto;
}

.projects__item__wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  /* background-color: #1c1d25; */
  transition: all 350ms ease;
}

.projects__item:hover > .projects__item__wrapper:before {
  right: 0;
  width: 140%;
  opacity: 1;
  filter: blur(5px);
}

.projects__item_image {
  /* width: 100%; */
  /* height: 100%; */
  object-fit: contain;
  transition: all 300ms ease;
}

.projects__item:hover .projects__item_image {
  opacity: 0.7;
  filter: blur(6px);
  transform: scale(1.05);
}

.projects__item_info {
  position: absolute;
  transform: translateY(100%);
  top: 30%;
  margin-left: 55px;
  opacity: 0;
  transition: all 200ms ease;
}

.projects__item_info h2 {
  padding-top: 10px;
  font-weight: lighter;
}

.projects__index {
  color: black;
  transition: all 300ms ease;
  transform: translateX(100%);
  opacity: 0;
  font-size: 100px;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 22px;
}

.projects__item button {
  transition: all 200ms ease;
  transform: translateX(-100%);

  opacity: 0;
  width: 190px;
  position: absolute;
  right: 100px;
  bottom: 60px;
  background-color: #001f3f;
  color: rgb(226, 226, 226);
}

.projects__item:hover .projects__item_info {
  opacity: 1;
  z-index: 2;
  transform: translateY(20%);
}

.projects__item:hover button {
  opacity: 1;
  transform: translateX(0);
}

.projects__item:hover .projects__index {
  opacity: 1;
  transform: translateX(0);
}

/* media queries */

@media screen and (max-width: 1300px) {
  .projects__item {
    width: 800px !important;
  }
}

@media screen and (max-width: 990px) {
  .projects__item {
    width: 680px !important;
  }
}

@media screen and (max-width: 930px) {
  .projects__item {
    width: 580px !important;
  }

  .projects__item:hover .projects__item_info {
    transform: translateY(-25%);
  }
}

@media screen and (max-width: 768px) {
  .projects__item {
    width: 440px !important;
  }

  .projects__item_info {
    font-size: 14px;
  }
  .projects__item:hover .projects__item_info {
    transform: translateY(-20%);
  }

  .projects__item button {
    right: 1.5rem;
    bottom: 1.8rem;
  }

  .projects__index {
    font-size: 70px;
  }
}
